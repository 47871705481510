<template>
  <default-layout>
    <!-- Slider -->
    <section class="slide-sect">
      <!-- <img src="@/assets/icons/arrow.png" alt id="left-arrow-intro" />
      <slick ref="slick" :options="slickOptions">
        <div
          v-for="piece in news[$i18n.locale]"
          :key="piece.id"
          class="slide"
          @click="slideClick(piece)"
          :style="{ backgroundImage: 'url(' + piece.thumbnail + ')' }"
        >
        </div>
      </slick>
      <img src="@/assets/icons/arrow.png" alt id="right-arrow-intro" /> -->
      <div>
        <img src="~@/assets/banner.jpg" width="100%" />
      </div>
    </section>
    <!-- Introduction -->
    <section class="introduction-sect">
      <h1>{{ $t("index.intro") }}</h1>
      <div class="introduction-sect__container">
        <img src="~@/assets/tapthe.jpg" alt="intro-image" />
        <div>
          <p v-html="$t('index.content')"></p>
          <br />
          <br />
          <router-link class="readmore" to="/introduction">{{
            $t("index.readmore")
          }}</router-link>
        </div>
      </div>
    </section>
    <section class="news-sect">
      <div class="news-sect__container">
        <h1>{{ $t("index.news") }}</h1>
        <slick class="newslick" ref="newsslick" :options="slickNewsOptions">
          <NewsCard
            class="news-sect__card"
            style="width: 200px"
            v-for="piece in news[$i18n.locale]"
            :key="piece.id"
            :pieceOfNews="piece"
          ></NewsCard>
        </slick>
        <router-link class="readmore news--readmore" to="/news">{{
          $t("index.readmore")
        }}</router-link>
      </div>
    </section>
    <section class="feature-sect">
      <h1>{{ $t("index.edu") }}</h1>
      <div class="feature-sect__container">
        <div class="feature-sect__card" @click="to('/academic')">
          <img src="~@/assets/icons/1.png" alt="" />
          <h3>{{ $t("index.studyprogram") }}</h3>
        </div>
        <div class="feature-sect__card" @click="to('/academic')">
          <img src="~@/assets/icons/2.png" alt="" />
          <h3>{{ $t("index.quality") }}</h3>
        </div>
        <div class="feature-sect__card" @click="to('/lecturer')">
          <img src="~@/assets/icons/3.png" alt="" />
          <h3>{{ $t("index.lecturer") }}</h3>
        </div>
      </div>
    </section>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/layout/DefaultLayout";
// import BaseCard from "@/components/BaseCard";
import NewsCard from "@/components/NewsCard";
import Slick from "vue-slick";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";

export default {
  data() {
    return {
      slickOptions: {
        nextArrow: "#right-arrow-intro",
        prevArrow: "#left-arrow-intro",
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 6000,
        fade: true,
        cssEase: "linear",
      },
      slickNewsOptions: {
        focusOnSelect: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 5000,
      },
      information: [],
      news: {},
    };
  },
  methods: {
    slideClick(piece) {
      this.to(`/news/${piece.id}`);
    },
    to(path) {
      console.log(path);
      this.$router.push(path);
    },
  },
  beforeUpdate() {
    if (this.$refs.slick) {
      this.$refs.slick.destroy();
    }
    if (this.$refs.newsslick) {
      this.$refs.newsslick.destroy();
    }
  },
  updated() {
    this.$nextTick(function () {
      if (this.$refs.slick) {
        this.$refs.slick.create(this.slickOptions);
      }
      if (this.$refs.newsslick) {
        this.$refs.newsslick.create(this.slickNewsOptions);
      }
    });
  },
  async created() {
    const response = await this.$axios.get("/api/posts");
    this.news = response.data;
  },
  mounted() {
    let wrapper = document.getElementsByClassName("news-sect__card");
    for (let index = 0; index < wrapper.length; index++) {
      const element = wrapper[index].parentElement;
      element.style.margin = "auto 20px";
    }
  },
  components: {
    DefaultLayout,
    // BaseCard,
    Slick,
    NewsCard,
  },
};
</script>

<style lang="scss">
@import "~@/assets/styles/index.scss";
.news--readmore {
  color: white !important;
}
</style>
