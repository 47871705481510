<template>
  <div>
    <!-- <a :href="`/news/${piece.id}`"> -->
    <div>
      <img :src="pieceOfNews.thumbnail" alt="" />
    </div>
    <h3>{{ pieceOfNews.title }}</h3>
    <time pubdate :datetime="$date(pieceOfNews.date).format('YYYY-MM-DD')">{{
      $date(pieceOfNews.date).format("DD/MM/YYYY")
    }}</time>
  </div>
</template>

<script>
export default {
  props: {
    pieceOfNews: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>